import {Toast} from 'native-base';

export const showErrorMessage = (description: string) => {
  Toast.show({
    title: `${description}`,
    variant: 'solid',
  });
};

export const showSuccessMessage = (description: string) => {
  Toast.show({
    title: description,
    backgroundColor: '#3AA76D',
  });
};

export const showInformationMessage = (description: string) => {
  Toast.show({
    title: description,
  });
};

export const showMessageWithCopy = (description: string) => {
  Toast.show({
    title: description,
  });
};

export const showPermissionMessage = () => {
  Toast.show({
    title: 'No permission to perform this operation',
  });
};
