import React from 'react';
import {Pressable, StyleSheet, View} from 'react-native';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import Text from '../../../components/Text';
import { RootLocation } from '../types';
import { makeId } from '../../../utils';
import { getIconForFile } from '../utils';
import NoFiles from './NoFiles';

interface I_FileView_Props {
  data: Array<RootLocation>;
  updateActiveItem: any;
  openFile: (path: string) => void;
  isRoot: boolean;
}

export const iconMapping = {
  word: 'file-word',
  pdf: 'file-pdf-box',
  image: 'image',
  video: 'video',
  audio: 'music',
};

const FileView = (props: I_FileView_Props) => {
  return (
    <View style={fileViewStyles.container}>
      {props.data?.length > 0 ? (
        <>
          {props.data.map(item => {
            const icon = getIconForFile(item.path);
            return (
              <Pressable
                key={item.siteId + makeId(12)}
                onPress={() => {
                  if (item.type !== 'folder') {
                    // @ts-ignore
                    props.openFile(item.path);
                    return;
                  }
                  props.updateActiveItem({
                    name: item.name,
                    // @ts-ignore
                    id: item.id,
                    // @ts-ignore
                    relativeLocation: item.path,
                  });
                }}>
                <View style={fileViewStyles.sectionContainer}>
                  <View
                    style={[
                      fileViewStyles.iconContainer,
                      {
                        backgroundColor:
                          item.type != 'folder'
                            ? 'rgba(3, 155, 229, 0.2)'
                            : 'transparent',
                      },
                    ]}>
                    <MaterialCommunityIcon
                      name={item.type != 'folder' ? icon : 'folder'}
                      color="#039be5"
                      size={item.type != 'folder' ? 20 : 60}
                    />
                  </View>
                </View>
                <View>
                  <Text
                    numberOfLines={2}
                    style={{textAlign: 'center', width: 80}}>
                    {item.name}
                  </Text>
                </View>
              </Pressable>
            );
          })}
        </>
      ) : (
       <NoFiles />
      )}
    </View>
  );
};

export default FileView;

const fileViewStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 8,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 2,
    backgroundColor: 'transparent',
  },
  sectionContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: 6,
    width: 90,
    padding: 2,
    borderRadius: 12,
  },
  iconContainer: {
    width: 60,
    height: 60,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
