import {Dispatch} from 'redux';
import {showErrorMessage, showSuccessMessage} from '../../service/flashMessage';
import {
  fetchLinksByUnit,
  getUnitLinkById,
  updateUnitPeopleLink,
} from '../../api/links';
import * as RootNavigation from '../../navigation';
import {UnitLink} from '../../@types/UnitLink';
import {store} from '../../redux/store';
import {residentApiWithAuth} from '../../api/resident';

export const PEOPLE_LOADING = 'PEOPLE_LOADING';
export const PEOPLE_FAIL = 'PEOPLE_FAIL';
export const PEOPLE_SUCCESS = 'PEOPLE_SUCCESS';
export const PEOPLE_UPDATE_LOADING = 'PEOPLE_UPDATE_LOADING';
export const PEOPLE_UPDATE_FAIL = 'PEOPLE_UPDATE_FAIL';
export const PEOPLE_UPDATE_SUCCESS = 'PEOPLE_UPDATE_SUCCESS';
export const PEOPLE_SEARCH = 'PEOPLE_SEARCH';
export const PEOPLE_ADD = 'PEOPLE_ADD';
export const LINK_PROFILE_TO_UNITLINK = 'LINK_PROFILE_TO_UNITLINK';

export interface PeopleLoading {
  type: typeof PEOPLE_LOADING;
}

export interface PeopleFail {
  type: typeof PEOPLE_FAIL;
  error: string | object;
}

export interface PeopleSuccess {
  type: typeof PEOPLE_SUCCESS;
  payload: UnitLink[];
}

export interface PeopleUpdateLoading {
  type: typeof PEOPLE_UPDATE_LOADING;
}

export interface PeopleUpdateFail {
  type: typeof PEOPLE_UPDATE_FAIL;
  error: string | object;
}

export interface PeopleUpdateSuccess {
  type: typeof PEOPLE_UPDATE_SUCCESS;
  payload: UnitLink;
}

export interface PeopleSearch {
  type: typeof PEOPLE_SEARCH;
  searchTerm: string;
}

export interface PeopleAdd {
  type: typeof PEOPLE_ADD;
  peopleLink: UnitLink;
}

export interface LinkProfileToUnitLink {
  type: typeof LINK_PROFILE_TO_UNITLINK;
  personId: string;
}

export type PeopleDispatchTypes =
  | PeopleLoading
  | PeopleFail
  | PeopleSuccess
  | PeopleUpdateSuccess
  | PeopleUpdateLoading
  | PeopleUpdateFail
  | PeopleSearch
  | PeopleAdd;

export const GetPeople =
  () => async (dispatch: Dispatch<PeopleDispatchTypes>) => {
    try {
      dispatch({type: PEOPLE_LOADING});

      const defaultLink = store
        .getState()
        .units.unitLinks.filter(unitLink => unitLink.isDefault === true)[0];

      let peopleLinks: UnitLink[] = await fetchLinksByUnit(
        defaultLink?.unit.id,
      );

      //if current user doesn't have canManagePeople, they only see themselves
      const currentUserLink = peopleLinks.filter(
        link => link.person.id === defaultLink.person.id,
      )[0];
      if (!currentUserLink.canManagePeople) {
        peopleLinks = [currentUserLink];
      }
      dispatch({type: PEOPLE_SUCCESS, payload: peopleLinks});
    } catch (error) {
      // console.log('error', error);
      dispatch({type: PEOPLE_FAIL, error: error});
    }
  };

//If link belongs to current user
//  Add it to peopleLinks and unitLinks (redux and sqlite)
//If link doesn't belong to current user
//  Add it to peopleLinks (redux and sqlite)
export const CreatePeople =
  (peopleLink: UnitLink) => async (dispatch: Dispatch<PeopleDispatchTypes>) => {
    try {
      dispatch({type: PEOPLE_UPDATE_LOADING});

      //update state
      dispatch({type: PEOPLE_UPDATE_SUCCESS, payload: peopleLink});
    } catch (error) {
      console.log('CreatePeople' + error.message);
    }
  };

export const UpdatePeopleLink =
  (unitLinkId: string, unitLink: UnitLink) =>
  async (dispatch: Dispatch<PeopleDispatchTypes>) => {
    try {
      //Update in API,
      await updateUnitPeopleLink(unitLinkId, unitLink);
      const newUnitLink = await getUnitLinkById(unitLinkId);

      //update state
      dispatch({type: PEOPLE_UPDATE_SUCCESS, payload: newUnitLink});

      showSuccessMessage('Person link updated successfully');
      RootNavigation.goBack();
    } catch (error) {
      console.log(error);
      dispatch({type: PEOPLE_UPDATE_FAIL, error: error});
      showErrorMessage(error);
    }
  };

export const PeopleSearch =
  (searchTerm: string) => async (dispatch: Dispatch<PeopleDispatchTypes>) => {
    dispatch({type: PEOPLE_SEARCH, searchTerm: searchTerm});
  };

export const checkUnitPeopleCode = async (profileLinkCode: string) => {
  try {
    let res = await residentApiWithAuth().get(
      `/unitPeopleLinks/profileLinkCode/${profileLinkCode}`,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    showErrorMessage(error.message);
    // showErrorMessage(
    //   "We couldn't find that profile code. Profile codes are case sensitive. Please try again",
    // );
  }
};

export const linkPersonToUnitLink = async unitLinkData => {
  try {
    let res = await residentApiWithAuth().post(
      `/unitPeopleLinks/requestlink`,
      unitLinkData,
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    // showErrorMessage(
    //   "We couldn't find that profile code. Profile codes are case sensitive. Please try again",
    // );
  }
};

export const sendLinkingBarCode = async (unitPeopleLinkId) => {
  try {
    let res = await residentApiWithAuth().post(
      `/unitPeopleLinks/sendQrCode?unitPeopleLinkId=${unitPeopleLinkId}`
    );
    const result = res.data;
    if (result.errors) {
      throw new Error(result.errors[0]);
    } else {
      return result.data;
    }
  } catch (error) {
    // showErrorMessage(
    //   "We couldn't find that profile code. Profile codes are case sensitive. Please try again",
    // );
  }
}

interface DefaultStateI {
  loading: boolean;
  error?: string | object;
  peopleLinks?: UnitLink[];
  peopleLinksHolder: UnitLink[];
}

const defaultState: DefaultStateI = {
  loading: false,
  error: null,
  peopleLinks: [],
  peopleLinksHolder: [],
};

const peopleReducer = (
  state: DefaultStateI = defaultState,
  action: PeopleDispatchTypes,
): DefaultStateI => {
  switch (action.type) {
    case PEOPLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PEOPLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PEOPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        peopleLinks: action.payload,
        peopleLinksHolder: action.payload,
      };
    case PEOPLE_UPDATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PEOPLE_UPDATE_SUCCESS:
      const {id} = action.payload;
      const previousState = state.peopleLinks;
      const newState = previousState.map((item, _) => {
        if (item.id !== id) {
          return item;
        }
        return {
          ...item,
          ...action.payload,
        };
      });
      return {
        ...state,
        peopleLinks: newState,
        loading: false,
      };
    case PEOPLE_SEARCH:
      if (action.searchTerm) {
        const newPeople = state.peopleLinksHolder.filter(personLink => {
          const linkFirstName = personLink.firstName
            ? `${personLink.firstName}`.toUpperCase()
            : ''.toUpperCase();
          const linkLastName = personLink.lastName
            ? `${personLink.lastName}`.toUpperCase()
            : ''.toUpperCase();

          const firstName = personLink.person.firstName
            ? `${personLink.person.firstName}`.toUpperCase()
            : ''.toUpperCase();
          const middleName = personLink.person.middleName
            ? `${personLink.person.middleName}`.toUpperCase()
            : ''.toUpperCase();
          const lastName = personLink.person.lastName
            ? `${personLink.person.lastName}`.toUpperCase()
            : ''.toUpperCase();
          const nickName = personLink.person.nickname
            ? `${personLink.person.nickname}`.toUpperCase()
            : ''.toUpperCase();
          const searchTermData = action.searchTerm.toUpperCase();
          return (
            linkFirstName.indexOf(searchTermData) > -1 ||
            linkLastName.indexOf(searchTermData) > -1 ||
            firstName.indexOf(searchTermData) > -1 ||
            middleName.indexOf(searchTermData) > -1 ||
            lastName.indexOf(searchTermData) > -1 ||
            nickName.indexOf(searchTermData) > -1
          );
        });
        return {
          ...state,
          peopleLinks: newPeople,
        };
      } else {
        return {
          ...state,
          peopleLinks: state.peopleLinksHolder,
        };
      }

    default:
      break;
  }
  return state;
};

export default peopleReducer;
