import {residentApiWithAuth} from '../../api/resident';

const fetchSubDirectories = async (
  unitId: string,
  relativeLocation?: string,
) => {
  try {
    let url = `/files/subDirectories?unitId=${unitId}`;

    if (relativeLocation) {
      url = url + `&relativeLocation=${relativeLocation}`;
    }
    const res = await residentApiWithAuth().get(url);
    return res.data?.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

const previewFile = async (unitId, relativeLocation) => {
    let url = `/files?unitId=${unitId}&relativeLocation=${relativeLocation}&action=download`;
    const res = await residentApiWithAuth().get(url, {
      responseType: 'blob',
    });
    return res.data;
};

export {fetchSubDirectories, previewFile};
