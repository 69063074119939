import React, {useEffect, useState} from 'react';
import Container from '../../../components/Container';
import {AsterixDialog, LoadingSpinner, Navbar} from '../../../components';
import {StyleSheet, View} from 'react-native';
import {ScrollView} from 'react-native';
import {Center, Divider} from 'native-base';
import {FileView, NavBreadcrumbs} from '../components';
import {useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {fetchSubDirectories, previewFile} from '../api';
import RNFileViewer from 'react-native-file-viewer';

const Documents = ({navigation}) => {
  const unitLink = useSelector(
    (state: RootStore) => state.units.unitLinks,
  ).filter(link => link.isDefault === true)[0];
  const initialBreadcrumb = {
    name: 'Documents',
    id: 'Documents',
    relativeLocation: '/',
  };
  const [breadcrumbs, setBreadcrumbs] = useState([initialBreadcrumb]);
  const [activeItem, setActiveItem] = useState({
    id: 'Documents',
    name: 'Documents',
    relativeLocation: '/',
  });
  const [subDirs, setSubDirs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openingFile, setOpeningFile] = useState(false);

  const unitId = unitLink?.unit?.id;

  const updateActiveItem = ({name, id, relativeLocation}) => {
    const nextLocation =
      activeItem.relativeLocation?.length === 1
        ? relativeLocation
        : activeItem.relativeLocation + relativeLocation;
    setActiveItem({name, id, relativeLocation: nextLocation});
    const itemExists = breadcrumbs.includes(id);
    if (itemExists) {
      const itemIndex = breadcrumbs.indexOf(id);
      const nextBreadcrumbs = breadcrumbs.filter((breadcrumb, index) => {
        if (index <= itemIndex) {
          return breadcrumb;
        }
      });
      setBreadcrumbs(nextBreadcrumbs);
      return;
    }
    setBreadcrumbs([
      ...breadcrumbs,
      {name, id, relativeLocation: nextLocation},
    ]);
  };

  const navigateToBreadCrumb = crumb => {
    setActiveItem(crumb);
    // @ts-ignore
    const indexOfCurrentCrumb = breadcrumbs.findIndex(
      item => item.id === crumb.id,
    );
    if (indexOfCurrentCrumb > 0) {
      const nextCrumbs = breadcrumbs.slice(0, indexOfCurrentCrumb + 1);
      setBreadcrumbs(nextCrumbs);
    } else {
      setBreadcrumbs([initialBreadcrumb]);
    }
  };

  const openFile = async(filePath: string) => {
    const relativeLocation =
      breadcrumbs[breadcrumbs.length - 1].relativeLocation + filePath;
      try{
        setOpeningFile(true);
        await  fetchBlobData(relativeLocation, filePath);
      }
      catch(e){
      console.log(e)
      }
      finally{
        setOpeningFile(false);
      }
  };

  const fetchBlobData = async (relativeLocation, filePath) => {
      const res = await previewFile(unitId, relativeLocation);
      //Convert the Blob into a local URL
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const url = fileReader.result;
        handleOpenFile(url, filePath);
        return url;
      };
      fileReader.readAsDataURL(res);
  };

  const handleOpenFile = async (blobURL, filePath) => {
    const RNFetchBlob  =require('rn-fetch-blob').default;

    if (blobURL) {
      const localUri = await downloadFile(blobURL, filePath);
      if (localUri) {
        const _filePath = localUri;
        const externalFilePath = `${RNFetchBlob.fs.dirs.DownloadDir}${filePath}`;
          await RNFetchBlob.fs.cp(_filePath, externalFilePath);
          await RNFetchBlob.fs.exists(externalFilePath);
        
          RNFileViewer.open(externalFilePath)
      }
    }
  };

  const downloadFile = async (url, filePath) => {
    const RNFetchBlob  =require('rn-fetch-blob').default;

    const {fs} = RNFetchBlob;
    const _filePath = fs.dirs.DocumentDir + filePath;
    await RNFetchBlob.fs
      .writeFile(_filePath, url.split(',')[1], 'base64')
      .catch(e => {
        console.log('err', e);
      });
    return _filePath;
  };

  useEffect(() => {
    fetchDocumentDirectories(activeItem);
  }, [activeItem]);

  const fetchDocumentDirectories = async activeItem => {
    setLoading(true);
    try {
      const subDirectories = await fetchSubDirectories(
        unitId,
        activeItem.relativeLocation,
      );
      const _subDirs = subDirectories.map(subDir => {
        const subDirItems = subDir.name.split('-');
        subDirItems.splice(0, 1);
        const subDirId = subDirItems.join('-') || subDir.name;
        return {
          ...subDir,
          type: subDir.isFolder ? 'folder' : 'word',
          name: subDir.name.includes('-')
            ? subDir.name.split('-')[0]
            : subDir.name,
          id: subDirId,
          path: subDir.path,
        };
      });
      setSubDirs(_subDirs);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Navbar navigation={navigation} title="Documents" />
      <View style={documentStyles.container}>
        <ScrollView
          style={documentStyles.mainContent}
          contentContainerStyle={documentStyles.mainContentContainer}>
          <NavBreadcrumbs
            breadcrumbs={breadcrumbs}
            navigateToBreadCrumb={navigateToBreadCrumb}
          />
          <Divider />
          {loading ? (
            <Center style={{ flex: 1, height: 200}}>
              <LoadingSpinner />
            </Center>
          ) : (
            <FileView
              data={subDirs}
              updateActiveItem={updateActiveItem}
              openFile={filePath => openFile(filePath)}
              isRoot
            />
          )}
        </ScrollView>
      </View>
      <AsterixDialog
        message={
          openingFile ? 'Opening file ...' : 'Loading ...'
        }
        isSwitching={openingFile}
      />
    </Container>
  );
};

const documentStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  mainContent: {
    width: '70%',
    backgroundColor: '#fff',
  },
  mainContentContainer: {
    padding: 10,
  },
});

export default Documents;
