import {Alert} from 'react-native';

const showAlert = (
  title: string,
  message: string,
  noClickHandler: any,
  yesClickHandler: any,
) => {
  Alert.alert(
    title,
    message,
    [
      {
        text: 'NO',
        onPress: () => noClickHandler(),
        style: 'cancel',
      },
      {
        text: 'YES',
        onPress: () => yesClickHandler(),
      },
    ],
    {
      cancelable: true,
    },
  );
  return true;
};

export default showAlert;
