import React, {useEffect} from 'react';
import {useState} from 'react';
import {View, ScrollView, TouchableOpacity, Image} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import Icon from 'react-native-vector-icons/Feather';
import {DisabledInput, LoadingSpinner, Navbar} from '../../../components';
import {styles} from '../../../styles';
import Button from '../../../components/Button';
import TextArea from '../../../components/TextArea';
import Checkbox from '../../../components/Checkbox';
import {Unit} from '../types';
import {fetchUnitById, updateUnit} from '../../../api/units';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../service/flashMessage';
import DateTimeInput from '../../../components/DateTimeInput';
import Container from '../../../components/Container';
import AsterixSwitch from '../../../components/AsterixSwitch';

const EditUnit = ({navigation, route}) => {
  const [unit, setUnit] = useState<Unit>(route.params.unit);
  const [loadingUnit, setLoadingUnit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateDates = () => {
    return (
       buildDate(unit.startBlockAutomatedCalls).getTime() <
       buildDate(unit.endBlockautomatedCalls).getTime()
    );
  };

  const submitData = async () => {
    const datesValid = validateDates();
    if (!!unit.allowAutomatedCalls && !datesValid) {
      showErrorMessage('Calls stop time needs to be greater than start time ');
      return;
    }

    setIsSubmitting(true);
    try {
      await updateUnit(unit.id, {
        specialInstructions: unit.specialInstructions,
        startBlockAutomatedCalls: unit.startBlockAutomatedCalls,
        endBlockautomatedCalls: unit.endBlockautomatedCalls,
        allowAutomatedCalls: unit.allowAutomatedCalls,
      });
      showSuccessMessage('Unit information updated successfully');
      navigation.goBack();
    } catch (error) {
      showErrorMessage(error.message);
    }
    setIsSubmitting(false);
  };

  const fetchUnit = async (id: string) => {
    setLoadingUnit(true);
    try {
      const res = await fetchUnitById(id);
      setUnit(res);
    } catch (error) {
      // showErrorMessage(error.message);
    } finally {
      setLoadingUnit(false);
    }
  };

  useEffect(() => {
    fetchUnit(unit.id);
  }, [unit.id]);

  const buildDate = (time: string) => {
    const today = new Date();
    const hours = parseInt(time.split(":")[0], 10);
    const minutes = parseInt(time.split(":")[1].split(":")[0], 10);
    const date = today.setHours(hours, minutes);
    return new Date(date);
  }

  return (
    <Container>
      <Navbar navigation={navigation} title={'Edit unit'} />
      {loadingUnit ? (
        <LoadingSpinner />
      ) : (
        <ScrollView style={{paddingHorizontal: 8}}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: 8,
            }}>
            <Text style={styles.checkBoxLabel}>Allow automated calls ?</Text>
            <AsterixSwitch
              value={Boolean(unit.allowAutomatedCalls)}
              onValueChange={value => {
                setUnit({...unit, allowAutomatedCalls: value});
              }}
            />
          </View>

          {!!unit.allowAutomatedCalls && (
            <>
              <DateTimeInput
                label="Call restriction start time: *"
                value={
                  !!unit.startBlockAutomatedCalls  ? buildDate(unit.startBlockAutomatedCalls) : null
                }
                onConfirm={time => {
                  if(typeof time === "string"){
                    setUnit({
                      ...unit,
                      startBlockAutomatedCalls: time,
                    });
                  }
                }}
                mode="time"
                description="Time to restart Automated Visitor Authorization calls"
              />
              <DateTimeInput
                label="Call restriction stop time: *"
                value={
                  !!unit.endBlockautomatedCalls ?  buildDate(unit.endBlockautomatedCalls) : null
                }
                onConfirm={time => {
                  if(typeof time === "string"){
                    setUnit({
                      ...unit,
                      endBlockautomatedCalls: time,
                    });
                  }
                }}
                mode='time'
                description="Time to Stop Visitor Automated calls"
              />
            </>
          )}
          <>
            <>
              <Text style={styles.textInputLabel}>Special Instructions *</Text>
              <TextArea
                placeholder="Enter special instructions"
                value={unit.specialInstructions ? unit.specialInstructions : ''}
                onChangeText={text =>
                  setUnit({...unit, specialInstructions: text})
                }
                style={tailwind('border-green-400 rounded')}
                totalLines={4}
                autoCompleteType=""
              />
            </>
            <DisabledInput title="Unit name" value={unit.unit} />
            <DisabledInput title="Unit display" value={unit.unitDisplay} />
            <View>
              <Text style={styles.textInputLabel}>Unit picture*</Text>
              <TouchableOpacity style={styles.pictureUploadContainer}>
                {unit?.picture ? (
                  <Image
                    source={{
                      uri: `data:image/jpeg;base64,${unit?.picture}`,
                    }}
                    style={tailwind('rounded w-32 h-24')}
                  />
                ) : (
                  <Icon
                    name="image"
                    size={24}
                    style={styles.pictureUploadIcon}
                  />
                )}
              </TouchableOpacity>
            </View>
            <DisabledInput
              title="Site"
              value={unit.unit ? unit.unit : unit.unitDisplay}
            />
            <DisabledInput title="Community" value={unit.communityName} />
            <DisabledInput title="Building" value={unit.buildingName} />
            <DisabledInput title="Status" value={unit.status} />
            <DisabledInput
              title="Enter addressLine1"
              value={unit.addressLine1}
            />
            <DisabledInput
              title="Enter addressLine2"
              value={unit.addressLine2}
            />
            <DisabledInput title="City" value={unit?.city} />
            <DisabledInput title="State" value={unit?.state} />
            <DisabledInput title="Zip" value={unit?.zip} />
            <DisabledInput title="Floor" value={unit?.floor} />
            <DisabledInput title="Line" value={unit?.line} />
            <DisabledInput title="Account Number" value={unit.accountNumber} />
            <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
              <Text style={styles.checkBoxLabel}>Community Delinquent ?</Text>
              <Checkbox
                style={tailwind('mt-2')}
                isDisabled
                value={unit.communityDelinquent}
                aria-label="Community Delinquent"
              />
            </View>
            <View style={tailwind('flex-row justify-between  pt-1 pb-3')}>
              <Text style={styles.checkBoxLabel}>Building Delinquent ?</Text>
              <Checkbox
                isDisabled
                value={unit.buildingDelinquent}
                aria-label='Building Delinquent'
              />
            </View>
            <DisabledInput title="Directions" value={unit.directions} />
            <Button
              onPress={() => {
                submitData();
              }}
              style={{marginBottom: 16, marginTop: 32}}
              isLoading={isSubmitting}
              py={4}
              isLoadingText="Submitting ...">
              Save
            </Button>
          </>
        </ScrollView>
      )}
    </Container>
  );
};

export default EditUnit;
