var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryClient = exports.default = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _objectDestructuringEmpty2 = _interopRequireDefault(require("@babel/runtime/helpers/objectDestructuringEmpty"));
var _react = _interopRequireWildcard(require("react"));
var _navigation = require("./src/navigation");
var _AppNavigator = _interopRequireDefault(require("./src/navigation/AppNavigator"));
var _components = require("./src/components");
var _reactQuery = require("react-query");
var _asyncStorage = _interopRequireDefault(require("@react-native-async-storage/async-storage"));
var _types = require("./src/@types");
var _jwtDecode = _interopRequireDefault(require("jwt-decode"));
var _reactRedux = require("react-redux");
var _people = require("./src/api/people");
var _flashMessage = require("./src/service/flashMessage");
var _styles = require("./src/styles");
var _isWeb = _interopRequireDefault(require("./src/utils/isWeb"));
var _notistack = require("notistack");
var _PendingApprovalsBadge = _interopRequireDefault(require("./src/components/PendingApprovalsBadge"));
var Sentry = _interopRequireWildcard(require("@sentry/react-native"));
var _store = require("./src/redux/store");
var _reducer = require("./src/modules/Auth/reducer");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var queryClient = exports.queryClient = new _reactQuery.QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity
    }
  }
});
var Application = function Application(_ref) {
  var _useSelector;
  (0, _objectDestructuringEmpty2.default)(_ref);
  var _React$useState = _react.default.useState(false),
    _React$useState2 = (0, _slicedToArray2.default)(_React$useState, 2),
    isReady = _React$useState2[0],
    setIsReady = _React$useState2[1];
  var _React$useState3 = _react.default.useState(),
    _React$useState4 = (0, _slicedToArray2.default)(_React$useState3, 2),
    initialState = _React$useState4[0],
    setInitialState = _React$useState4[1];
  var dispatch = (0, _reactRedux.useDispatch)();
  var _React$useState5 = _react.default.useState(false),
    _React$useState6 = (0, _slicedToArray2.default)(_React$useState5, 2),
    refreshingToken = _React$useState6[0],
    setRefreshingToken = _React$useState6[1];
  var pendingOwnerApprovals = (_useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state.approvals.approvals;
  })) === null || _useSelector === void 0 ? void 0 : _useSelector.filter(function (approval) {
    return approval.residentApprovalStatus === 'Pending';
  });
  var pendingOwnerApprovalsCount = (pendingOwnerApprovals === null || pendingOwnerApprovals === void 0 ? void 0 : pendingOwnerApprovals.length) || 0;
  function restoreState() {
    return _restoreState.apply(this, arguments);
  }
  function _restoreState() {
    _restoreState = (0, _asyncToGenerator2.default)(function* () {
      try {
        var savedStateString = yield _asyncStorage.default.getItem(_types.E_STORAGE_KEYS.NAVIGATION_PERSIST);
        var state = savedStateString ? JSON.parse(savedStateString) : undefined;
        var savedUserString = yield _asyncStorage.default.getItem(_types.E_STORAGE_KEYS.USER);
        var user = savedUserString ? JSON.parse(savedUserString) : undefined;
        if (user !== undefined) {
          _store.store.dispatch({
            type: _reducer.AUTH_SIGN_IN_SUCCESS,
            user: user
          });
          console.log("user restored from local storage");
        }
        if (state !== undefined) {
          setInitialState(state);
        }
        var tokenString = yield _asyncStorage.default.getItem(_types.E_STORAGE_KEYS.AUTH_TOKEN);
        var tokenObj = JSON.parse(tokenString);
        if (tokenObj) {
          var _tokenObj = JSON.parse(tokenString);
          if (isTokenExpired(_tokenObj.token)) {
            console.log("token expired ...");
            setRefreshingToken(true);
            (0, _people.getNewToken)().then(function (_ref2) {
              var token = _ref2.token,
                refreshToken = _ref2.refreshToken;
              _store.store.dispatch({
                type: _reducer.AUTH_SET_TOKEN,
                token: {
                  token: token,
                  refreshToken: refreshToken
                }
              });
              console.log("Token renewed");
              Sentry.captureMessage("Token renewed");
            }).catch(function (error) {
              var message = 'Token refresh failed , please login';
              Sentry.captureException(error);
              (0, _flashMessage.showInformationMessage)(message);
              _asyncStorage.default.removeItem(_types.E_STORAGE_KEYS.TOKEN_PERSIST).then(function () {
                dispatch({
                  type: _types.E_STORAGE_KEYS.CLEAR_REDUX_PERSIST_ROOT
                });
              });
            }).finally(function () {
              setRefreshingToken(false);
            });
          } else {
            console.log("Token loaded from local storage");
            _store.store.dispatch({
              type: _reducer.AUTH_SET_TOKEN,
              token: {
                token: _tokenObj.token,
                refreshToken: _tokenObj.refreshToken
              }
            });
          }
        } else {
          (0, _flashMessage.showInformationMessage)('Signing you out, please login to continue');
          _asyncStorage.default.removeItem(_types.E_STORAGE_KEYS.TOKEN_PERSIST).then(function () {
            dispatch({
              type: _types.E_STORAGE_KEYS.CLEAR_REDUX_PERSIST_ROOT
            });
          });
        }
      } finally {
        setIsReady(true);
      }
    });
    return _restoreState.apply(this, arguments);
  }
  (0, _react.useEffect)(function () {
    if (!isReady) {
      restoreState();
    }
  }, [isReady]);
  if (!isReady || refreshingToken) {
    return (0, _jsxRuntime.jsx)(_components.LoadingSpinner, {});
  }
  var onApprovalsBadgePress = function onApprovalsBadgePress() {
    (0, _navigation.navigate)('Alerts', {
      activeTab: 'Pending Approvals'
    });
  };
  return (0, _jsxRuntime.jsxs)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: [(0, _jsxRuntime.jsx)(_components.MyStatusBar, {
      backgroundColor: _styles.colorSwatches.primary.color,
      barStyle: "light-content"
    }), !!pendingOwnerApprovalsCount && (0, _jsxRuntime.jsx)(_PendingApprovalsBadge.default, {
      pendingApprovals: pendingOwnerApprovalsCount,
      onPress: onApprovalsBadgePress
    }), _isWeb.default && (0, _jsxRuntime.jsx)(_notistack.SnackbarProvider, {}), (0, _jsxRuntime.jsx)(_AppNavigator.default, {
      navigationRef: _navigation.navigationRef,
      initialState: initialState,
      onStateChange: function onStateChange(state) {
        _asyncStorage.default.setItem(_types.E_STORAGE_KEYS.NAVIGATION_PERSIST, JSON.stringify(state));
      }
    })]
  });
};
function isTokenExpired(token) {
  if (!token) {
    return true;
  }
  var decodedToken = (0, _jwtDecode.default)(token);
  var currentDate = new Date();
  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    return true;
  } else {
    return false;
  }
}
var _default = exports.default = Application;