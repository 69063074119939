const getIconForFile = (filename: string) => {
    const extension = filename.split('.').pop().toLowerCase();
  
    const iconMapping = {
      // Common document types
      'doc': 'file-word',
      'docx': 'file-word',
      'pdf': 'file-pdf',
      'txt': 'file-document-outline',
      'rtf': 'file-document-outline',
      'odt': 'file-document-outline',
  
      // Image files
      'jpg': 'file-image',
      'jpeg': 'file-image',
      'png': 'file-image',
      'gif': 'file-image',
      'bmp': 'file-image',
      'svg': 'file-image-outline',
  
      // Audio files
      'mp3': 'file-music',
      'wav': 'file-music',
      'ogg': 'file-music',
      'flac': 'file-music',
  
      // Video files
      'mp4': 'file-video',
      'avi': 'file-video',
      'mov': 'file-video',
      'mkv': 'file-video',
      'wmv': 'file-video',
  
      // Archive files
      'zip': 'file-archive',
      'rar': 'file-archive',
      '7z': 'file-archive',
      'tar': 'file-archive',
      'gz': 'file-archive',
  
      // Code files
      'js': 'file-code',
      'jsx': 'file-code',
      'ts': 'file-code',
      'tsx': 'file-code',
      'html': 'file-code',
      'css': 'file-code',
      'json': 'file-code',
  
      // Spreadsheet files
      'xls': 'file-excel',
      'xlsx': 'file-excel',
      'csv': 'file-excel',
  
      // Presentation files
      'ppt': 'file-powerpoint',
      'pptx': 'file-powerpoint',
  
      // Default icon for unknown extensions
      'default': 'file',
    };
  
    return iconMapping[extension] || iconMapping['default'];
  };

export  {getIconForFile};