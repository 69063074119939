import React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {CardStyleInterpolators} from '@react-navigation/stack';
import useColorMode from '../components/useColorMode';
import {useSelector} from 'react-redux';
import {E_NAVIGATION_DESTINATIONS} from '../@types';
import {UnitLink} from '../@types/UnitLink';
import {RootStore} from '../redux/store';
import Ionicon from 'react-native-vector-icons/Ionicons';
import FeatherIcon from 'react-native-vector-icons/Feather';

import {
  Alerts,
  NotificationDetails,
  Preferences,
} from '../modules/Alerts/screens';
import {
  CreateDateFrame,
  CreatePersonDateFrame,
} from '../modules/Dateframe/screens';
import {
  AddDelivery,
  Deliveries,
  DeliveryDetail,
  DeliveriesPackages,
} from '../modules/Deliveries/screens';
import {HowTo, PermissionsScreen, SettingsScreen} from '../screens/more';
import {
  AddWithUnitCode,
  CreateLinkRequest,
  CreatePeopleAndLink,
  CreateUnitPeopleLink,
  EditOwnUnitLink,
  LinkCodeGenerator,
} from '../modules/UnitPeopleLink/screens';
import {
  ApprovalsHistory,
  EditPeople,
  EditUnitLink,
  People,
  PeopleDetail,
} from '../modules/People/screens';
import {
  EditUnit,
  UnitCalendar,
  UnitChooser,
  UnitDetails,
} from '../modules/Units/screens';
import {permissionToBool} from '../utils';
import {ApprovalDetail} from '../modules/Alerts/screens';
import {UserPermissions} from '../modules/Permissions';
import {getUnclaimedPackagesCount} from '../utils/packages';
import IDCard from '../modules/Dashboard/screen/IDCard';
import {EditProfile} from '../modules/Auth/screens';
import VisitorLogsNavigator from '../modules/VisitorLogs/navigator';
import WebNavigator from './WebNavigator';
import isWeb from '../utils/isWeb';
import VehiclesNavigator from '../modules/Vehicles/navigator';
import PetsNavigator from '../modules/Pets/navigator';
import StoragesNavigator from '../modules/Storage/navigator';
import ParkingsNavigator from '../modules/Parking/navigator';
import GatecardsNavigator from '../modules/Gatecards/navigator';
import KeyLogsNavigator from '../modules/Keylogs/navigator';
import DoorLogsNavigator from '../modules/DoorLogs/navigator';
import PackageHistoryNavigator from '../modules/Deliveries/navigator';
import ViolationsNavigator from '../modules/Violations/navigator';
import {Directory, EditDirectoryInfo} from '../modules/Directory/screens';
import {PictureZoom} from '../screens/picturezoom';
import {RootState} from '../redux/reducers';
//@ts-ignore
import DevicePermissions from '../screens/more/DevicePermissions';
import About from '../screens/more/About';
import QuickAccessFAB from '../components/QuickAccessFAB';
import {navigate} from '.';
import PackageDetails from '../modules/Deliveries/screens/PackageDetails.web';
import { Documents } from '../modules/Documents/screens';

const options = {
  headerShown: false,
  cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
};

const Tab = createBottomTabNavigator();

export const TabNavigator = () => {
  const {colorMode} = useColorMode();
  const unitLink = useSelector(
    (state: RootState) =>
      state.units.unitLinks?.filter(link => link.isDefault === true)[0],
  );

  const unreadNotifs = useSelector((state: RootStore) => state.alerts.unread);
  let unclaimedPackagesCount = useSelector((state: RootStore) =>
    getUnclaimedPackagesCount(state?.packages?.packages),
  );

  const optionsWithBadge = {
    tabBarLabel: 'Alerts',
    tabBarIcon: ({color}: {color: string}) => (
      <FeatherIcon name="bell" color={color} size={20} />
    ),
    tabBarBadge: unreadNotifs + ' Unread',
  };

  const optionsWithoutBadge = {
    tabBarLabel: 'Alerts',
    tabBarIcon: ({color}: {color: string}) => (
      <FeatherIcon name="bell" color={color} size={20} />
    ),
  };

  const packagesWithBadge = {
    tabBarLabel: 'Packages',
    tabBarIcon: ({color}: {color: string}) => (
      <FeatherIcon name="package" color={color} size={20} />
    ),
    tabBarBadge: unclaimedPackagesCount + ' ',
  };

  const packagesWithoutBadge = {
    tabBarLabel: 'Packages',
    tabBarIcon: ({color}: {color: string}) => (
      <FeatherIcon name="package" color={color} size={20} />
    ),
  };

  const backgroundColor = colorMode === 'light' ? '#fafafa' : '#27272a';

  const badgeOptions =
    unreadNotifs > 0 ? optionsWithBadge : optionsWithoutBadge;
  const packagesOptions =
  unclaimedPackagesCount > 0 ? packagesWithBadge : packagesWithoutBadge;

  const openHowTo = () => {
    navigate(E_NAVIGATION_DESTINATIONS.HOWTO, {});
  };

  return (
    <>
      <Tab.Navigator
        sceneContainerStyle={{
          backgroundColor: colorMode === 'light' ? '#fafafa' : '#27272a',
        }}
        screenOptions={{
          lazy: true,
          headerShown: false,
          tabBarStyle: {
            backgroundColor: backgroundColor,
          },
        }}>
        <Tab.Screen name="Alerts" component={Alerts} options={badgeOptions} />
        {
          <Tab.Screen
            name="Units"
            component={UnitChooser}
            options={{
              tabBarIcon: ({color}) => (
                <FeatherIcon name="home" color={color} size={20} />
              ),
            }}
          />
        }
        {permissionToBool(unitLink?.peoplePermission?.charAt(0)) && (
          <Tab.Screen
            name="People"
            component={People}
            options={{
              tabBarIcon: ({color}) => (
                <FeatherIcon name="users" color={color} size={16} />
              ),
            }}
          />
        )}
        {permissionToBool(unitLink?.deliveryPermission?.charAt(0)) && (
          <Tab.Screen
            name="Deliveries & Packages"
            component={DeliveriesPackages}
            options={packagesOptions}
          />
        )}
        <Tab.Screen
          name="Others"
          component={SettingsScreen}
          options={{
            tabBarLabel: 'Others',
            tabBarIcon: ({color}) => (
              <Ionicon name="list-outline" color={color} size={20} />
            ),
          }}
        />
      </Tab.Navigator>
      <QuickAccessFAB onPress={openHowTo} />
    </>
  );
};

const buildRoutes = (defaultUnitLink: UnitLink, hasUnitLinks: boolean) => {
  let screenWithPermissions = [
    {
      name: 'Dashboard',
      component: isWeb ? WebNavigator : TabNavigator,
      canView: true,
    },
    {
      name: 'Units Chooser',
      component: UnitChooser,
      canView: permissionToBool(defaultUnitLink?.unitPermission?.charAt(0)),
    },
    {
      name: 'Unit Details',
      component: UnitDetails,
      canView: permissionToBool(defaultUnitLink?.unitPermission?.charAt(0)),
    },
    {
      name: 'Edit Person',
      component: EditPeople,
      canView: permissionToBool(defaultUnitLink?.peoplePermission?.charAt(1)),
    },
    {
      name: 'Approvals History',
      component: ApprovalsHistory,
      canView: true,
    },
    {
      name: 'Edit Person Link',
      component: EditUnitLink,
      canView: true,
    },
    {
      name: 'Alerts',
      component: Alerts,
      canView: true,
    },
    {
      name: 'Approval Detail',
      component: ApprovalDetail,
      canView: true,
    },
    {
      name: 'Permissions',
      component: PermissionsScreen,
      canView: true,
    },
    {
      name: 'Person Details',
      component: PeopleDetail,
      canView: true,
    },
    {
      name: 'Link person to unit',
      component: CreateUnitPeopleLink,
      canView: true,
    },
    {
      name: 'Link Request',
      component: CreateLinkRequest,
      canView: true,
    },
    {
      name: 'Create Date Frame',
      component: CreateDateFrame,
      canView: true,
    },
    {
      name: 'Create Person DateFrame',
      component: CreatePersonDateFrame,
      canView: true,
    },
    {
      name: 'Generate Link Code',
      component: LinkCodeGenerator,
      canView: true,
    },
    {
      name: 'Deliveries',
      component: Deliveries,
      canView: true,
    },
    {
      name: E_NAVIGATION_DESTINATIONS.VISITOR_LOGS,
      component: VisitorLogsNavigator,
      canView: defaultUnitLink?.canViewUnitHistory,
    },
    {
      name: E_NAVIGATION_DESTINATIONS.ALERTS_PREFERENCES,
      component: Preferences,
      canView: defaultUnitLink?.getNotifications,
    },
    {
      name: E_NAVIGATION_DESTINATIONS.ADD_WITH_UNITCODE,
      component: AddWithUnitCode,
      canView: true,
    },
    {
      name: E_NAVIGATION_DESTINATIONS.PICTURE_ZOOM,
      component: PictureZoom,
      canView: true,
    },

    {
      name: E_NAVIGATION_DESTINATIONS.EDIT_OWN_UNITLINK,
      component: EditOwnUnitLink,
      canView: true,
    },
    // {
    //   name: E_NAVIGATION_DESTINATIONS.ID_CARD,
    //   component: IDCard,
    //   canView: true,
    // },
    // {
    //   name: E_NAVIGATION_DESTINATIONS.EDIT_PROFILE,
    //   component: EditProfile,
    //   canView: true,
    // },

    // {
    //   name: E_NAVIGATION_DESTINATIONS.ADD_UNIT_WITH_BARCODE,
    //   component: AddUnitWithBarcode,
    //   canView: true,
    // },
  ];

  if(isWeb) {
    screenWithPermissions = screenWithPermissions.concat([
      {
        name: E_NAVIGATION_DESTINATIONS.OTHERS,
        component: SettingsScreen,
        canView: true,
      },
      {
        name: 'People',
        component: People,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.PACKAGES,
        component: DeliveriesPackages,
        canView: true,
      },
    ])
  }
  // if (!isWeb) {
    screenWithPermissions = screenWithPermissions.concat([
      {
        name: E_NAVIGATION_DESTINATIONS.VEHICLES,
        component: VehiclesNavigator,
        canView: permissionToBool(
          defaultUnitLink?.vehiclePermission?.charAt(0),
        ),
      },
      {
        name: 'Pets',
        component: PetsNavigator,
        canView: permissionToBool(defaultUnitLink?.petPermission?.charAt(0)),
      },
      {
        name: E_NAVIGATION_DESTINATIONS.STORAGES,
        component: StoragesNavigator,
        canView: permissionToBool(
          defaultUnitLink?.storagePermission?.charAt(0),
        ),
      },
      {
        name: E_NAVIGATION_DESTINATIONS.PARKINGS,
        component: ParkingsNavigator,
        canView: permissionToBool(
          defaultUnitLink?.parkingPermission?.charAt(0),
        ),
      },
      {
        name: E_NAVIGATION_DESTINATIONS.GATE_CARDS,
        component: GatecardsNavigator,
        canView: permissionToBool(
          defaultUnitLink?.gateCardPermission?.charAt(0),
        ),
      },
      {
        name: E_NAVIGATION_DESTINATIONS.KEY_LOGS,
        component: KeyLogsNavigator,
        canView:
          defaultUnitLink?.allowedToChekoutKey &&
          defaultUnitLink.canViewUnitHistory,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.DOOR_LOGS,
        component: DoorLogsNavigator,
        canView: defaultUnitLink?.canViewUnitHistory,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.PACKAGES_HISTORIES,
        component: PackageHistoryNavigator,
        canView:
          defaultUnitLink?.canViewUnitHistory &&
          defaultUnitLink.allowedToClaimPackages,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.PACKAGE_DETAILS,
        component: PackageDetails,
        canView:
          defaultUnitLink?.canViewUnitHistory &&
          defaultUnitLink.allowedToClaimPackages,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.VIOLATIONS,
        component: ViolationsNavigator,
        canView: permissionToBool(
          defaultUnitLink?.violationsPermission?.charAt(0),
        ),
      },
      {
        name: 'Directory',
        component: Directory,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.EDIT_DIRECTORY_SETTINGS,
        component: EditDirectoryInfo,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.NOTIFICATION_SETTINGS,
        component: DevicePermissions,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.ABOUT,
        component: About,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.HOWTO,
        component: HowTo,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.EDIT_PROFILE,
        component: EditProfile,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.EDIT_UNIT,
        component: EditUnit,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.ID_CARD,
        component: IDCard,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.NOTIFICATION_DETAILS,
        component: NotificationDetails,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.UNIT_CALENDAR,
        component: UnitCalendar,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.USER_PERMISSIONS,
        component: UserPermissions,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.DELIVERY_DETAILS,
        component: DeliveryDetail,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.ADD_DELIVERY,
        component: AddDelivery,
        canView: permissionToBool(defaultUnitLink?.deliveryPermission?.charAt(3)),
      },
      {
        name: E_NAVIGATION_DESTINATIONS.CREATE_PEOPLE_AND_LINK,
        component: CreatePeopleAndLink,
        canView: true,
      },
      {
        name: E_NAVIGATION_DESTINATIONS.DOCUMENTS,
        component: Documents,
        canView: true,
      },
    ]);
  // }
  screenWithPermissions = screenWithPermissions.map(screen => {
    return {
      ...screen,
      options,
    };
  });
  return screenWithPermissions;
};

export default buildRoutes;
