import React from 'react';
import {ScrollView} from 'react-native';
import {Navbar} from '../../../components';
import {deriveDisplayName, getCurrentUnitLink} from '../../../utils';
import AccessCard from '../components/AccessCard';
import {deriveUnitName} from '../../../utils/deriveDisplayName';
import Container from '../../../components/Container';

const IDCard = ({navigation}) => {

  const unitPeopleLink = getCurrentUnitLink();
  const unitPeopleLinkPicture = unitPeopleLink?.profilePicture
    ? `data:image/jpeg;base64,${unitPeopleLink?.profilePicture}`
    : 'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png';
  const personProfilePicture = unitPeopleLink?.person.profilePicture
    ? `data:image/jpeg;base64,${unitPeopleLink?.person.profilePicture}`
    : 'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/default-avatar.png';
  const personType = unitPeopleLink?.peopleType.name;
  const unitPeopleLinkName = deriveDisplayName(unitPeopleLink)?.toUpperCase();
  const personName = deriveDisplayName(unitPeopleLink.person)?.toUpperCase();
  const unitDisplayName = !!unitPeopleLink.unit.unitDisplay
    ? unitPeopleLink.unit.unitDisplay
    : deriveUnitName(unitPeopleLink);


  return (
    <Container>
      <Navbar navigation={navigation} title="My ID Card" />
        <ScrollView>
          <AccessCard
            personProfilePicture={personProfilePicture}
            unitPeopleLinkName={unitPeopleLinkName}
            personName={personName}
            personType={personType}
            allowedInToday={unitPeopleLink.dateFrameDay?.allowedToday}
            id={unitPeopleLink.person.id}
            unitDisplay={unitDisplayName}
            siteName={unitPeopleLink.unit.site.name}
            buildingName={unitPeopleLink.unit.building.name}
            communityName={unitPeopleLink.unit.community.name}
          />
        </ScrollView>
    </Container>
  );
};

export default IDCard;
