import {Dimensions, Platform, StatusBar, Appearance} from 'react-native';
import tailwind from 'tailwind-rn';

const colorScheme = Appearance.getColorScheme();

export const DEFAULT_THEME = {
  PRIMARY_COLOR: '#47B275',
  DEFAULT_GREY: 'grey',
};

export const colorSwatches = {
  primary: {
    // color: '#007bff',
    color: '#039be5',
  },
  secondary: {
    color: '#47B275',
  },
  gray: {
    color: 'gray',
  },
  white: {
    color: '#ffffff',
  },
  yellow: {
    color: '#facc15',
  },
  green: {
    color: '#4ade80',
  },
  orange: {
    200: '#fed7aa',
    500: '#f97316',
  },
  blue: {
    200: '#bfdbfe',
    500: '#3b82f6',
  },
  red: {
    400: '#f87171',
  },
};

export const styles = {
  primaryColor: {
    color: '#007bff',
  },
  secondaryColor: {
    color: '#47B275',
  },
  appBar: {
    backgroundColor: '#007bff',
  },
  fab: {
    backgroundColor: '#007bff',
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 0,
  },
  textInput: {
    height: 40,
    borderColor: '#CBD5E0',
    borderWidth: 1,
    borderRadius: 4,
    fontSize: 17,
    padding: 8,
    flex: 1,
    color: colorScheme === 'dark' ? 'white' : 'black',
    marginBottom: 16,
  },
  editableTextInput: {
    height: 40,
    borderColor: '#47B275',
    borderWidth: 1,
    borderRadius: 4,
    fontSize: 17,
    padding: 8,
    flex: 1,
    color: 'black',
    marginBottom: 16,
  },
  paperTextInput: {
    height: 40,
    borderRadius: 4,
    fontSize: 17,
    padding: 8,
    flex: 1,
    color: 'black',
    marginBottom: 16,
  },
  promptTextInput: {
    height: 40,
    borderColor: '#CBD5E0',
    borderWidth: 1,
    borderRadius: 4,
    fontSize: 17,
    padding: 8,
    color: 'black',
    marginBottom: 16,
  },
  passwordTextContainer: {
    height: 40,
    borderColor: '#CBD5E0',
    borderWidth: 1,
    borderRadius: 2,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  passwordTextInput: {
    padding: 8,
    fontSize: 17,
  },
  textInputWithError: {
    height: 40,
    borderColor: 'red',
    borderWidth: 1,
    borderRadius: 2,
    fontSize: 17,
    padding: 8,
    flex: 1,
    color: 'black',
    marginBottom: 16,
  },
  multiLinetextInput: {
    height: 100,
    // borderColor: '#CBD5E0',
    // borderWidth: 1,
    // borderRadius: 4,
    // fontSize: 17,
    // padding: 8,
  },
  editableMultiLinetextInput: {
    height: 100,
    borderColor: '#47B275',
    borderWidth: 1,
    borderRadius: 4,
    fontSize: 17,
    padding: 8,
  },
  textInputLabel: {
    // color: colorScheme === 'dark' ? 'white' : 'black',
    color: 'black',
    marginBottom: 4,
    marginTop: 8,
    flex: 1,
    fontSize: 15,
  },
  dateInputLabel: {
    color: 'black',
    marginBottom: 4,
    marginTop: 8,
    fontSize: 15,
  },
  checkBoxLabel: {
    color: '#52525b',
    marginBottom: 4,
    flex: 1,
    fontSize: 15,
    marginTop: 8,
  },
  smallText: {
    color: '#52525b',
    marginTop: 20,
    padding: 4,
  },
  pictureUploadContainer: {
    ...tailwind('border border-gray-400 rounded w-32 h-24'),
  },
  licenseUploadContainer: {
    ...tailwind('border border-gray-400 bg-white rounded w-full h-64'),
  },
  editablePictureUploadContainer: {
    ...tailwind('border rounded w-32 h-24'),
    borderColor: '#47B275',
  },
  pictureUploadIcon: {
    marginTop: 36,
    marginStart: 36,
    ...tailwind('text-gray-600'),
  },
  licenseUploadIcon: {
    marginTop: 36,
    marginStart: 146,
    ...tailwind('text-gray-600'),
  },
  submitButton: {
    ...tailwind('flex-row m-2 items-center justify-center py-3 rounded'),
    backgroundColor: '#007bff',
  },
  submitButtonWithBorder: {
    ...tailwind('flex-row m-2 items-center justify-center py-3 rounded'),
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#007bff',
  },
  selectDefaultButtonWithBorder: {
    ...tailwind('flex-1 m-2 py-3 h-16 rounded'),
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#007bff',
  },
  submitButtonText: {
    ...tailwind('flex-1 rounded text-white text-center font-semibold text-lg'),
  },
  submitButtonWithBorderText: {
    ...tailwind('flex-1 rounded  text-center  font-semibold text-lg'),
    color: '#007bff',
  },
  gridView: {
    flex: 1,
    marginHorizontal: 8,
    marginTop: -480,
    paddingTop: 16,
  },
  editIcon: {
    left: 4,
    top: -5,
  },
  defaultUnitImage: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: 'gray',
  },
  cardImage: {
    width: 116,
    height: 104,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
  },
  emptyDetailsCard: {
    width: 116,
    height: 104,
    borderRadius: 4,
    backgroundColor: 'gray',
  },
  unitsCardImage: {
    width: 156,
    height: 156,
    marginLeft: 4,
    borderRadius: 6,
    marginTop: 0,
  },
  unitsModalImage: {
    width: 100,
    height: 90,
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    backgroundColor: 'gray',
  },
  userImage: {
    width: 46,
    height: 46,
    borderRadius: 23,
  },
  userImagePlaceholder: {
    width: 46,
    height: 46,
    borderRadius: 23,
    backgroundColor: 'gray',
  },
  profileImage: {
    width: 76,
    height: 76,
    margin: 0.5,
    borderRadius: 38,
  },
  notificationImage: {
    width: 56,
    height: 56,
    margin: 0.5,
    borderRadius: 28,
  },
  detailsImage: {
    height: 100, 
    margin: 0.5,
    borderRadius: 4,
    marginBottom: 8,
    width: 200,
  },
  textLabel: {
    color: '#787688',
    marginBottom: 4,
    flex: 1,
    fontSize: 15,
  },
  detailsText: {
    // ...tailwind('flex-1'),
    color: '#787688',
    marginBottom: 4,
    flex: 1,
    fontSize: 15,
  },
  dashboardProfilePicture: {
    paddingTop: 0,
    flexDirection: 'row',
    // justifyContent: 'flex-end',
  },
  onePickerLabel: {
    color: '#2D3748',
    marginTop: Platform.OS === 'ios' ? 96 : 16,
    flex: 1,
    fontSize: 15,
  },
  onePicker: {
    width: 156,
    marginTop: 0,
  },
  onePickerItem: {
    backgroundColor: 'transparent',
  },
  panelTitle: {
    fontSize: 19,
    marginBottom: 16,
  },
  sheetTitle: {
    fontSize: 17,
    fontWeight: '300',
    marginBottom: 8,
    marginTop: 8,
  },
  sheetLabel: {
    fontSize: 16,
    fontWeight: '400',
    marginTop: 16,
    marginBottom: 8,
  },
  image: {
    width: 96,
    height: 96,
    borderRadius: 48,
  },
  settingsUserImage: {
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  settingsUnitImage: {
    width: 48,
    height: 40,
    borderRadius: 6,
  },
  userName: {
    fontSize: 16,
    flex: 1,
    // color: colorScheme === 'dark' ? '#ffffff' : '#000000',
  },
  settingsLabel: {
    fontSize: 14,
    // color: colorScheme === 'dark' ? '#ffffff' : '#000000',
    paddingLeft: 8,
    paddingTop: 4,
    ...tailwind('text-gray-600'),
  },
  address: {fontSize: 15},
  currentUser: {
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 4,
    width: 46,
    marginLeft: 16,
    marginTop: 4,
    ...tailwind('bg-green-400'),
  },
  alertResponseSuccess: {
    ...tailwind('bg-green-400'),
    marginBottom: 4,
    color: '#10B981',
    borderRadius: 16,
    textAlign: 'center',
    // marginRight: 16,
    paddingLeft: 4,
    paddingRight: 4,
  },
  alertResponseDanger: {
    backgroundColor: '#fca5a5',
    marginBottom: 4,
    color: '#EF4444',
    borderRadius: 16,
    textAlign: 'center',
    marginLeft: 16,
    paddingLeft: 4,
    paddingRight: 4,
  },
  detailResponseDanger: {
    backgroundColor: '#fca5a5',
    marginBottom: 4,
    color: '#EF4444',
    borderRadius: 16,
    textAlign: 'center',
    paddingLeft: 4,
    paddingRight: 4,
  },

  searchInput: {
    borderColor: '#c0c0c0',
    borderWidth: 1,
    borderRadius: 2,
    margin: 8,
    height: 40,
  },
  searchBar: {
    ...tailwind('rounded-none'),
    height:  56,
    paddingTop: 4,
    elevation: 0,
    width: '100%',
  },
  navBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: Platform.OS === 'ios' ? 0 : 1,
    paddingBottom: Platform.OS === 'ios' ? 0 : 8,
    elevation: Platform.OS === 'ios' ? 0 : 8,
    backgroundColor: colorSwatches.primary.color,
  },
  backContainer: {marginEnd: 12, marginTop: -1},
  search: {
    flex: 1,
  },
  title: {
    fontSize: 18,
    flex: 1,
    ...tailwind('ml-3'),
    color: colorSwatches.white.color,
  },
  tabBar: {
    flexDirection: 'row',
    height: StatusBar.currentHeight,
  },
  tabBarItem: {
    padding: 12,
  },
  drawerImage: {
    height: 150,
    width: 150,
    borderRadius: 75,
  },
  header: {
    height: 175,
    backgroundColor: '#fff',
  },
  name: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 2,
  },
  email: {
    fontSize: 12,
  },
  unitsModal: {
    top: {
      marginBottom: 'auto',
      marginTop: 0,
    },
    bottom: {
      marginBottom: 0,
      marginTop: 'auto',
    },
    left: {
      marginLeft: 0,
      marginRight: 'auto',
    },
    right: {
      marginLeft: 'auto',
      marginRight: 0,
    },
    center: {},
  },
  errorText: {fontSize: 14, color: 'red'},
  panelDarkTheme: {
    style: {backgroundColor: '#1f1f1f'},
    barStyle: {backgroundColor: 'rgba(255,255,255,0.2)'},
    closeRootStyle: {backgroundColor: 'rgba(255,255,255,0.2)'},
  },
};
