// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {CardStyleInterpolators} from '@react-navigation/stack';
import SignInScreen from '../modules/Auth/screens/SignInScreen';
import {ForgotPassword, Welcome} from '../modules/Auth/screens';
import {createNativeStackNavigator} from '@react-navigation/native-stack';

import {RootStackParamList} from '../@types';
import buildRoutes from './routes';
import {
  AccountSetupInfo,
  DlCapture,
  Email,
  EmailConfirmation,
  Name,
  Phone,
  PhoneConfirmation,
  PhotoCapture,
  RegisterProgress,
  Success,
  UserCredentials,
} from '../modules/Auth/screens';
import {makeId} from '../utils';
import {UnitList} from '../modules/Units/screens';
import injectFonts from '../utils/injectFonts';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../redux/reducers';
import {
  NavigationContainer,
  getPathFromState,
  getStateFromPath,
} from '@react-navigation/native';
import {setCurrentRoute} from './reducer';

import {vehicleScreens} from '../modules/Vehicles/navigator';
import {petScreens} from '../modules/Pets/navigator';
import {storageScreens} from '../modules/Storage/navigator';
import {parkingScreens} from '../modules/Parking/navigator';
import {gateCardScreens} from '../modules/Gatecards/navigator';
import {violationScreens} from '../modules/Violations/navigator';
import {visitorLogScreens} from '../modules/VisitorLogs/navigator';
import {keyLogScreens} from '../modules/Keylogs/navigator';
import {doorLogScreens} from '../modules/DoorLogs/navigator';
import {packageHistoryScreens} from '../modules/Deliveries/navigator';

import theme from '../theme';
import {
  Provider as PaperProvider,
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
} from 'react-native-paper';
import {NativeBaseProvider} from 'native-base';
import Ionicons from 'react-native-vector-icons/Ionicons';
import InviteCode from '../modules/Auth/screens/InviteCode';

injectFonts();

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function AppNavigator({
  navigationRef,
  initialState,
  onStateChange,
}) {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state.auth.token);
  const defaultUnitPeopleLink = useSelector(
    (state: RootState) =>
      state.units.unitLinks?.filter(link => link.isDefault === true)[0],
  );
  const hasUnitLinks = useSelector(
    (state: RootState) => state.units.unitLinks.length > 0,
  );
  const [routesWithPermissions, setRoutesWithPermission] = useState(
    buildRoutes(defaultUnitPeopleLink),
  );
  
  useEffect(() => {
    setRoutesWithPermission(buildRoutes(defaultUnitPeopleLink, hasUnitLinks));
  }, [defaultUnitPeopleLink, hasUnitLinks]);
  const options = {
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    headerShown: false,
  };

  const linking = {
    screens: {
      'Sign In': 'signin',
      'Account Setup Info': 'register/info',
      'Dl Capture': 'register/captureDL',
      Name: 'register/name',
      'Photo Capture': 'register/capturePhoto',
      Email: 'register/email',
      Phone: 'regsiter/phone',
      'Email Confirmation': 'register/confirmEmail',
      'Phone Confirmation': 'register/confirmPhone',
      'User Credentials': 'register/credentials',
      Success: 'register/success',
      'Forgot Password': 'forgotPassword',
      'Add with UnitCode': 'addwithUnitCode',
      'Generate Link Code': 'generateLinkCode',
      'ID Card': '/idCard',
      'Unit Calendar': '/unitCalendar',
      Dashboard: '/dashboard',
      Pets: '/pets',
      Storages: '/storages',
      'Parking List': '/parking',
      Violations: '/violations',
      Directory: '/directory',
      'Key Logs': '/keyLogs',
      'Visitor History': '/visitorHistory',
      'Access History': '/accessHistory',
      'Packages History': '/packagesHistory',
      'Door Logs': '/doorLogs',
      'Notification Details': '/notificationDetails',

      Vehicles: '/other/vehicles',
      ...vehicleScreens,

      // Pets: '/other/pets',
      // ...petScreens,

      Storages: '/other/storages',
      ...storageScreens,

      Parkings: '/other/parkings',
      ...parkingScreens,

      'Gate Cards': '/other/gateCards',
      ...gateCardScreens,

      Violations: '/other/violations',
      ...violationScreens,

      Directory: '/other/directory',
      'Edit Directory Settings': '/other/directory/settings',

      'Visitor Logs': '/other/visitorLogs',
      ...visitorLogScreens,

      'Key Logs': '/other/keyLogs',
      ...keyLogScreens,

      'Door Logs': '/other/doorLogs',
      ...doorLogScreens,

      'Packages History': '/other/packagesHistory',
      ...packageHistoryScreens,

      Alerts: '/alerts',
      Units: '/units',
      People: '/people',
      'Deliveries & Packages': '/deliveries-packages',
      Others: '/others',

      'Add with UnitCode': '/units/add-with-linkcode',
    },
    getStateFromPath: (path, options) => {
      let state = getStateFromPath(path, options);
      return state;
    },
    getPathFromState: (state, config) => {
      let path = getPathFromState(state, config);
      return path;
    },
  };

  return (
    <NavigationContainer
      ref={navigationRef}
      initialState={initialState}
      onStateChange={state => {
        onStateChange(state);
        const currentRouteName = navigationRef?.current?.getCurrentRoute().name;
        dispatch(setCurrentRoute(currentRouteName));
      }}
      linking={linking}>
      <PaperProvider
        settings={{
          icon: props => <Ionicons {...props} />,
        }}
        theme={PaperDefaultTheme}>
        <NativeBaseProvider theme={theme}>
          <Stack.Navigator>
            {token === null || token === undefined ? (
              <>
                <Stack.Screen
                  name="Sign In"
                  component={SignInScreen}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Welcome"
                  component={Welcome}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Account Setup Info"
                  component={AccountSetupInfo}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Invite Code"
                  component={InviteCode}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Dl Capture"
                  component={DlCapture}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Name"
                  component={Name}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Photo Capture"
                  component={PhotoCapture}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Email"
                  component={Email}
                  options={{...options, animationTypeForReplace: 'pop'}}
                />
                <Stack.Screen
                  name="Phone"
                  component={Phone}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Email Confirmation"
                  component={EmailConfirmation}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />

                <Stack.Screen
                  name="Register Progress"
                  component={RegisterProgress}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Phone Confirmation"
                  component={PhoneConfirmation}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />

                <Stack.Screen
                  name="User Credentials"
                  component={UserCredentials}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Success"
                  component={Success}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
                <Stack.Screen
                  name="Forgot Password"
                  component={ForgotPassword}
                  options={{...options, animationTypeForReplace: 'pop'}}
                  key={makeId(12)}
                />
              </>
            ) : (
              <>
                {defaultUnitPeopleLink === null ||
                  (defaultUnitPeopleLink === undefined && (
                    <Stack.Screen
                      name="Units"
                      component={UnitList}
                      options={{...options}}
                      key={makeId(12)}
                    />
                ))}
                {routesWithPermissions.map((route, index) => (
                      <Stack.Screen
                        name={route.name}
                        component={route.component}
                        options={{...route.options}}
                        key={index}
                      />
                ))}
              </>
            )}
          </Stack.Navigator>
        </NativeBaseProvider>
      </PaperProvider>
    </NavigationContainer>
  );
}
