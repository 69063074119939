import CameraScreen from './CameraScreen';
import EditProfile from './EditProfile';
import ForgotPassword from './ForgotPassword';
import SignInScreen from './SignInScreen';
import Welcome from './Welcome';

import AccountSetupInfo from './AccountSetupInfo';
import Name from './Name';
import Email from './Email';
import EmailConfirmation from './EmailConfirmation';
import Phone from './Phone';
import PhoneConfirmation from './PhoneConfirmation';
import DlCapture from './DlCapture';
import UserCredentials from './UserCredentials';
import Success from './Success';
import RegisterProgress from './RegisterProgress';
import PhotoCapture from './PhotoCapture';
import AddContact from './AddContact';
import ConfirmationCodesEntry from './ConfirmationCodesEntry';

export {
  CameraScreen,
  EditProfile,
  ForgotPassword,
  SignInScreen as SignIn,
  Welcome,
  AccountSetupInfo,
  Name,
  Email,
  EmailConfirmation,
  Phone,
  PhoneConfirmation,
  DlCapture,
  UserCredentials,
  Success,
  RegisterProgress,
  PhotoCapture,
  AddContact,
  ConfirmationCodesEntry
};
