import * as React from 'react';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import {Pressable, StyleSheet, View} from 'react-native';
import {colorSwatches} from '../../../styles';

const NavBreadcrumbs = ({breadcrumbs, navigateToBreadCrumb}) => {
  return (
    <Box style={styles.container}>
      {breadcrumbs?.map((crumb, index) => (
        <View key={crumb.name}>
          <Pressable
            onPress={() => {
              navigateToBreadCrumb(crumb);
            }}>
            <View style={styles.crumbContainer}>
              {index > 0 && <Text> / </Text>}
              <Text
                style={[{color: colorSwatches.primary.color, fontSize: 16}]}>
                {crumb.name}
              </Text>
            </View>
          </Pressable>
        </View>
      ))}
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    display: 'flex',
    flexDirection: 'row',
    marginHorizontal: 8,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  crumbContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default NavBreadcrumbs;
