import React, {useEffect, useState} from 'react';
import {Platform, View, Text, Pressable} from 'react-native';
import {LoadingSpinner, Navbar} from '../../../components';
import WebView from 'react-native-webview';
import {previewFile} from '../api';
import Container from '../../../components/Container';
import RNFileViewer from 'react-native-file-viewer';

const FileViewer = ({navigation, route}) => {
  const {relativeLocation, unitId, filePath} = route.params;
  const [blobURL, setBlobURL] = useState(null);

  useEffect(() => {
    fetchBlobData();
  }, []);

  const fetchBlobData = async () => {
    try {
      const res = await previewFile(unitId, relativeLocation);

      //Convert the Blob into a local URL
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        const url = fileReader.result;
        setBlobURL(url);
        return url;
      };
      fileReader.readAsDataURL(res);
    } catch (error) {
      console.error('Error fetching blob:', error);
    }
  };

  const handleOpenFile = async () => {
    const RNFetchBlob = require('rn-fetch-blob');

    if (blobURL) {
      const localUri = await downloadFile(blobURL);
      if (localUri) {
        const _filePath = localUri;
        const externalFilePath = `${RNFetchBlob.fs.dirs.DownloadDir}${filePath}`;
        try {
          await RNFetchBlob.fs.cp(_filePath, externalFilePath);
          const fileExists = await RNFetchBlob.fs.exists(externalFilePath);
        
          const path = RNFileViewer.open(externalFilePath)
            .then(res => {
              console.log('res', res);
              // success
            })
            .catch(error => {
              console.log('error', error);
              // error
            });
        } catch (e) {}
      }
    }
  };

  const downloadFile = async url => {
    const RNFetchBlob = require('rn-fetch-blob');
    const {fs} = RNFetchBlob;
    const _filePath = fs.dirs.DocumentDir + filePath;
    await RNFetchBlob.fs
      .writeFile(_filePath, url.split(',')[1], 'base64')
      .catch(e => {
        console.log('err', e);
      });
    return _filePath;
  };

  return (
    <Container>
      <Navbar navigation={navigation} title={filePath.split('/')[1]} />
      <View style={{flex: 1, backgroundColor: 'yellow'}}>
        {blobURL ? (
          <>
            {Platform.OS === 'ios' || Platform.OS === 'android' ? (
              <WebView
                originWhitelist={['*']}
                source={{uri: blobURL}}
                style={{flex: 1, marginBottom: 160}}
              />
            ) : (
              <Text>Blob URL: {blobURL}</Text>
            )}
          </>
        ) : (
          <LoadingSpinner />
        )}
        <Pressable onPress={handleOpenFile} style={{marginBottom: 100}}>
          <Text style={{textAlign: 'center'}}>Open file</Text>
        </Pressable>
      </View>
    </Container>
  );
};

export default FileViewer;
