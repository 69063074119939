// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {
  TouchableOpacity,
  Image,
  View,
  Keyboard,
  ScrollView,
  Platform,
} from 'react-native';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import Icon from 'react-native-vector-icons/Feather';
import {LoadingSpinner, Navbar} from '../../../components';
import {styles} from '../../../styles';
import soundxConverter from '../../../utils/soundexConverter';
import {showErrorMessage} from '../../../service/flashMessage';
import {postDriverLicense} from '../api';
import * as yup from 'yup';
import {User} from '../../../@types';
import {useDispatch, useSelector} from 'react-redux';
import {RootStore} from '../../../redux/store';
import {UpdateUser} from '../reducer';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import {useForm} from 'react-hook-form';
import TextInput from '../../../components/TextInput';
import Container from '../../../components/Container';
import PhotoCaptureSheet from '../../../components/PhotoCaptureSheet';
import ZoomableImage from '../../../components/ZoomableImage';
import FormContainer from '../../../components/FormContainer';
import {fetchPerson} from '../../../api/people';

const editValidationSchema = yup.object().shape({
  username: yup.string().required('Username  is Required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email Address is Required')
    .nullable(),
  phone1: yup.string().required('Phone1 is Required').nullable(),
  firstName: yup.string().required('First name is Required').nullable(),
  lastName: yup.string().required('Last name is Required').nullable(),
});

const EditProfile = ({navigation}) => {
  const {user, updateProfileLoading} = useSelector(
    (state: RootStore) => state.auth,
  );
  const [person, setPerson] = useState(user);
  const [authState, setAuthState] = useState<User>(person);
  const [dlValid, setDlValid] = useState(false);
  const [crunchingLicense, setCrunchingLicense] = useState(false);
  const [currentImageType, setCurrentImageType] = useState('');
  const [dlPanelActive, setDlPanelActive] = useState(false);
  const dispatch = useDispatch();
  const titles = ['Mr', 'Mrs', 'Ms', 'Dr'];
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadProfile(person.id);
  }, [person.id]);

  const loadProfile = async () => {
    setLoading(true);
    try {
      const _user = await fetchPerson(person.id);
      setPerson(_user);
      setAuthState(_user);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const {control, handleSubmit} = useForm({
    defaultValues: {
      username: person.username,
      email: person.email,
      phone1: person.phone1,
      firstName: person.firstName,
      lastName: person.lastName,
    },
  });

  const fetchDriverLicenseData = async base64String => {
    try {
      const res = await postDriverLicense(base64String);
      console.log("RES", res);
      updateStateWithLicense(res, base64String);
    } catch (e) {
      setDlValid(false);
      showErrorMessage(e.message);
    }

    setCrunchingLicense(false);
  };

  const updateStateWithLicense = (dlData, driversLicenseImage) => {
    if (dlData) {
      setDlValid(true);
      setAuthState({
        ...authState,
        firstName: dlData?.firstName,
        lastName: dlData?.lastName,
        driversLicenseImage: driversLicenseImage,
        addressLine1: dlData?.address1,
        addressLine2: dlData?.address2,
        dlNumber: dlData?.documentNumber,
        dateOfBirth: dlData?.dob,
        expiration: dlData?.expiry,
        gender: dlData?.sex,
      });
    } else {
      setDlValid(false);
      showErrorMessage('Please upload a valid license');
    }
  };

  const updateStateWithImages = (image, imageType) => {
    setAuthState({
      ...authState,
      [imageType]: image,
    });

    if (imageType === 'driversLicenseImage') {
      setCrunchingLicense(true);
      fetchDriverLicenseData(image);
    }
  };

  function handleFormSubmit(values) {
    Keyboard.dismiss();
    const combinedState = {
      ...values,
      firstName: authState?.firstName,
      lastName: authState?.lastName,
      profilePicture: authState.profilePicture,
      driversLicenseImage: authState.driversLicenseImage,
      addressLine1: authState?.addressLine1,
      addressLine2: authState?.addressLine2,
      dlNumber: authState?.dlNumber,
      dateOfBirth: authState?.dateOfBirth,
      expiration: authState?.expiration,
      gender: authState?.gender,
      firstnameSoundx: soundxConverter(values.firstName),
      lastNameSoundx: soundxConverter(values.lastName),
    };

    dispatch(UpdateUser(combinedState, person.id));
  }

  return (
    <Container hasDrawer>
      <Navbar navigation={navigation} title="Edit Profile" />
      <FormContainer>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <ScrollView
            contentContainerStyle={{
              paddingHorizontal: 8,
              marginBottom: 32,
            }}>
            <View>
              <Text style={[tailwind('my-2')]}>Profile picture</Text>
              <TouchableOpacity
                onPress={() => {
                  setCurrentImageType('profilePicture');
                  setDlPanelActive(true);
                }}
                style={styles.pictureUploadContainer}>
                {authState.profilePicture ? (
                  <Image
                    source={{
                      uri: `data:image/jpeg;base64,${authState.profilePicture}`,
                    }}
                    style={tailwind('rounded w-32 h-24')}
                  />
                ) : (
                  <Icon
                    name="image"
                    size={24}
                    style={styles.pictureUploadIcon}
                  />
                )}
              </TouchableOpacity>
            </View>
            <View>
              <Text style={[tailwind('my-2')]}>Drivers License picture</Text>
              <TouchableOpacity
                style={styles.pictureUploadContainer}
                onPress={() => {
                  setCurrentImageType('driversLicenseImage');
                  setDlPanelActive(true);
                }}> 
                {authState.driversLicenseImage ? (
                  <Image
                    source={{
                      uri: `data:image/jpeg;base64,${authState.driversLicenseImage}`,
                    }}
                    resizeMode="contain"
                    style={tailwind('rounded w-32 h-24')}
                  />
                ) : (
                  <Icon
                    name="image"
                    size={24}
                    style={styles.pictureUploadIcon}
                  />
                )}
              </TouchableOpacity>
            </View>
            <TextInput
              //@ts-ignore
              control={control}
              name="username"
              placeholder="e.g johndoe"
              label="Username"
              isDisabled
            />
            <>
              <Text style={[tailwind('my-2')]}>Nickname</Text>
              <Input
                placeholder="e.g john doe"
                value={authState.nickname}
                onChangeText={text =>
                  setAuthState({...authState, nickname: text})
                }
              />
            </>
            <TextInput
              //@ts-ignore
              control={control}
              name="email"
              placeholder="e.g johndoe@example.com"
              label="Email"
              keyboardType="email-address"
            />
            <>
              <TextInput
                //@ts-ignore
                control={control}
                name="phone1"
                placeholder="e.g +1 546 789 890"
                label="Phone"
                keyboardType="phone-pad"
              />
              <View style={tailwind('flex-row my-3')}>
                <Text style={styles.checkBoxLabel}>
                  Phone 1 Can receive Text messages?
                </Text>
                <Checkbox
                  disabled={false}
                  value={authState.phone1CanReceiveText}
                  onValueChange={value =>
                    setAuthState({
                      ...authState,
                      phone1CanReceiveText: value,
                    })
                  }
                  aria-label="Phone 1 can receive text"
                />
              </View>
            </>
            <>
              <Text style={[tailwind('my-2')]}>Address Line 1</Text>
              <Input
                placeholder="Enter Address Line 1"
                value={authState.addressLine1}
                onChangeText={text =>
                  setAuthState({...authState, addressLine1: text})
                }
              />
            </>
            <>
              <Text style={[tailwind('my-2')]}>Address Line 2</Text>
              <Input
                placeholder="Enter Address Line 2"
                value={authState.addressLine2}
                onChangeText={text =>
                  setAuthState({...authState, addressLine2: text})
                }
              />
            </>
            <TextInput
              //@ts-ignore
              control={control}
              name="firstName"
              placeholder="e.g John"
              label="First Name"
              isDisabled
            />
            <>
              <Text style={[tailwind('my-2')]}>Middle name</Text>
              <Input
                placeholder="Enter middle name"
                value={authState.middleName}
                onChangeText={text =>
                  setAuthState({...authState, middleName: text})
                }
                isDisabled
              />
            </>
            <TextInput
              //@ts-ignore
              control={control}
              name="lastName"
              placeholder="e.g Doe"
              label="Last Name"
              isDisabled
            />
            <>
              <Text style={[tailwind('my-2')]}>Company</Text>
              <Input
                placeholder="Enter company"
                value={authState.company}
                onChangeText={text =>
                  setAuthState({...authState, company: text})
                }
                isDisabled
              />
            </>
            <>
              <Text style={[tailwind('my-2')]}>City</Text>
              <Input
                placeholder="Enter city"
                value={authState.city}
                onChangeText={text => setAuthState({...authState, city: text})}
                isDisabled
              />
            </>
            <>
              <Text style={[tailwind('my-2')]}>State</Text>
              <Input
                placeholder="Enter state"
                value={authState.state}
                onChangeText={text => setAuthState({...authState, state: text})}
                isDisabled
              />
            </>
            <>
              <Text style={[tailwind('my-2')]}>Zip</Text>
              <Input
                placeholder="Enter zip"
                value={authState.zip}
                onChangeText={text => setAuthState({...authState, zip: text})}
                isDisabled
              />
            </>
            <Text style={tailwind('mt-4')}>Title</Text>
            <Select
              minWidth={200}
              selectedValue={authState.title}
              isDisabled
              onValueChange={val => setAuthState({...authState, title: val})}>
              {titles.map(title => (
                <Select.Item key={title} label={title} value={title} />
              ))}
            </Select>
            <>
              <Text style={[tailwind('my-2')]}>Notes</Text>
              <Input
                placeholder="Enter notes"
                value={authState?.notes}
                multiline
                onChangeText={text => setAuthState({...authState, notes: text})}
              />
            </>
          </ScrollView>
        )}
        {!loading && (
          <Button
            onPress={handleSubmit(handleFormSubmit)}
            size="lg"
            py="3"
            mx="2"
            mb={Platform.OS === 'ios' ? '24' : '8'}
            mt="8"
            isLoading={updateProfileLoading}
            isLoadingText="Submitting ..."
            isDisabled={updateProfileLoading}>
            {'Update' + ' '}
          </Button>
        )}
        <PhotoCaptureSheet
          isPanelActive={dlPanelActive}
          setIsActive={(val: boolean) => setDlPanelActive(val)}
          onConfirmImage={imgData =>
            updateStateWithImages(imgData, currentImageType)
          }
          cropping
        />
      </FormContainer>
    </Container>
  );
};

export default EditProfile;
