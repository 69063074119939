import React from 'react';
import {Card} from 'react-native-paper';
import {View, ImageBackground} from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import Text from '../../../components/Text';
import tailwind from 'tailwind-rn';
import ZoomableImage from '../../../components/ZoomableImage';

const AccessCard = ({
  personProfilePicture,
  unitPeopleLinkName,
  personName,
  personType,
  allowedInToday,
  id,
  unitDisplay,
  siteName,
  buildingName, 
  communityName
}) => {
  return (
    <Card
      style={{
        marginHorizontal: 8,
        marginBottom: 16,
        borderRadius: 6,
        marginTop: 16,
      }}>
      <ImageBackground
        source={require('../assets/card_background.jpg')}
        resizeMode="cover"
        imageStyle={{borderTopLeftRadius: 7, borderTopRightRadius: 7}}
        style={{height: 360, width: '100%'}}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: 16,
          }}>
          <View
            style={{
              width: 71,
              height: 20,
              backgroundColor: '#ffffff',
              borderRadius: 12,
            }}></View>
        </View>
        <View style={{flexDirection: 'column', margin: 16, marginTop: 16}}>
          <Text
            style={{
              color: '#ffffff',
              fontWeight: '600',
              paddingTop: 16,
              paddingBottom: 16,
              fontSize: 16,
            }}>
            {siteName}
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <View style={{borderRadius: 6}}>
              <ZoomableImage
                title="My picture in unit"
                style={{
                  width: 170,
                  height: 200,
                  borderRadius: 5,
                  borderWidth: 6,
                  borderColor: '#ffffff',
                }}
                source={{uri: personProfilePicture}}
              />
            </View>
            <View
              style={{
                marginLeft: 16,
              }}>
              <Text
                style={{
                  color: '#ffffff',
                  fontWeight: '700',
                  fontSize: 21,
                }}>
                {personType}
              </Text>
              <Text
                style={{
                  fontWeight: '700',
                  color: '#ffffff',
                  marginTop: 16,
                }}>
                {`Unit: ${unitDisplay}`}
              </Text>
              <Text
                style={{
                  color: '#ffffff',
                  marginTop: 16,
                }}>
                {`Building: ${buildingName}`}
              </Text>
              <Text
                style={{
                  color: '#ffffff',
                  marginTop: 16,
                }}>
                {`Community: ${communityName}`}
              </Text>

              <View style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between', alignItems: 'center'}}>
                <Text
                  style={{
                    color: '#ffffff',
                    marginTop: 16,
                    marginBottom: 10,
                  }}>
                  Access Today
                </Text>
                <View style={tailwind('flex-row h-6 ml-1 mt-2')}>
                  <View
                    style={tailwind(
                      `bg-${allowedInToday ? 'green' : 'red'}-400 rounded-full px-2`,
                    )}>
                    <Text
                      style={tailwind('text-xs text-white mx-1 mr-1')}
                      numberOfLines={1}>
                      Today
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Text
            style={{
              fontSize: 18,
              fontWeight: '700',
              color: '#ffffff',
              marginTop: 16,
            }}>
            {`${personName} (${unitPeopleLinkName})`}
          </Text>
        </View>
      </ImageBackground>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 16,
        }}>
        <QRCode value={`P${id}`} size={100} />
      </View>
    </Card>
  );
};

export default AccessCard;
