import {Center} from 'native-base';
import React from 'react';
import Text from '../../../components/Text';

const NoFiles = () => {
  return (
    <Center
      style={{
        flex: 1,
        height: 100,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
      }}>
      <Text style={{textAlign: 'center', fontSize: 16}}>No files here</Text>
    </Center>
  );
};

export default NoFiles;
